import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import FullLogo from '../images/logo/Full.svg';
import HLogo from '../images/logo/H.svg';
import StravaLogo from '../images/social/Strava.svg';
import FacebookLogo from '../images/social/Facebook.svg';
import InstagramLogo from '../images/social/Instagram.svg';
import { Button } from 'grommet';
import withAnalyticsEvent from './AnalyticsEvents/withAnalyticsEvent';

const defaultOnClick = () => {
  navigate('/');
};

//eslint-disable-next-line react/prop-types
const LinkedLogo = ({ Logo, height, width, href, onClick }) => (
  <Button
    onClick={href ? null : onClick || defaultOnClick}
    focusIndicator={false}
    label={null}
    icon={<Logo height={height} width={width} />}
    href={href}
  />
);

const EventButton = withAnalyticsEvent(Button, {
  eventAction: 'click',
  eventCategory: 'outbound',
  eventLabel: '', // let default to the href
});

//eslint-disable-next-line react/prop-types
const LinkedLogoEvent = ({ Logo, height, width, href, onClick }) => (
  <EventButton
    onClick={href ? null : onClick || defaultOnClick}
    focusIndicator={false}
    label={null}
    icon={<Logo height={height} width={width} />}
    href={href}
  />
);

export const Full = ({ height, width }) => (
  <LinkedLogo Logo={FullLogo} height={height} width={width} />
);

export const HWing = ({ height, width }) => (
  <LinkedLogo Logo={HLogo} height={height} width={width} />
);

export const Strava = props => (
  <LinkedLogoEvent
    Logo={StravaLogo}
    href="//www.strava.com/pros/16010204"
    {...props}
  />
);

export const Instagram = props => (
  <LinkedLogoEvent
    Logo={InstagramLogo}
    href="//www.instagram.com/hawks_hayden/"
    {...props}
  />
);

export const Facebook = props => (
  <LinkedLogoEvent
    Logo={FacebookLogo}
    href="//www.facebook.com/hayden.hawks.581"
    {...props}
  />
);

const propTypes = {
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  href: PropTypes.string,
};

HWing.propTypes = propTypes;

Full.propTypes = propTypes;

Strava.propTypes = propTypes;

Facebook.propTypes = propTypes;

Instagram.propTypes = propTypes;

LinkedLogo.propTypes = Object.assign(propTypes, { Logo: PropTypes.func });
