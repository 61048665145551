import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Box } from 'grommet';

const BoxMin = styled(Box)`
  min-width: ${props => props.minWidth};
  min-height: ${props => props.minHeight};
`;

const SectionContainer = props => {
  const { background, ...rest } = props;

  return (
    <BoxMin
      fill="horizontal"
      direction="row"
      justify="center"
      align="center"
      //background={background || 'light-1'}
      background={background}
      {...rest}
    >
      {props.children}
    </BoxMin>
  );
};

SectionContainer.propTypes = {
  background: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.objectOf(PropTypes.node),
  ]),
};

export default SectionContainer;
