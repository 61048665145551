import React, { useContext } from 'react';
import styled from 'styled-components';
import { ResponsiveContext } from 'grommet';
import PropTypes from 'prop-types';
import posed from 'react-pose';
import MobileHeader from './mobileHeader';
import DesktopHeader from './desktopHeader';

// Example of a component-specific page transition
const AnimatedContainer = styled(
  posed.div({
    enter: {
      y: 0,
      transition: {
        ease: 'easeInOut',
      },
    },
    exit: {
      y: '-100%',
      transition: {
        ease: 'easeInOut',
      },
    },
  })
)`
  display: flex;
`;

const FullWidthHeader = styled.header`
  width: 100%;
`;

const Header = ({ fixed }) => {
  // const theme = useContext(ThemeContext);
  // console.log('grommet theme: ', theme);

  const size = useContext(ResponsiveContext);
  // console.log('size: ', size);

  if (size === 'small') {
    return (
      <FullWidthHeader>
        <AnimatedContainer>
          <MobileHeader fixed />
        </AnimatedContainer>
      </FullWidthHeader>
    );
  } else {
    return (
      <FullWidthHeader>
        <AnimatedContainer>
          <DesktopHeader />
        </AnimatedContainer>
      </FullWidthHeader>
    );
  }

  // return (
  //   <FullWidthHeader>
  //     <AnimatedContainer>
  //       {(size === 'small') && <MobileHeader fixed />}
  //       {(size === 'medium' || size === 'large') && (
  //         <DesktopHeader fixed={fixed} />
  //       )}
  //     </AnimatedContainer>
  //   </FullWidthHeader>
  // );
};

Header.propTypes = {
  fixed: PropTypes.bool,
};

export default Header;
