import React from 'react';

const withAnalyticsEvent = (Wrapped, eventOptions) => {
  const { eventAction, eventCategory, eventLabel } = eventOptions;

  if (!eventAction || !eventCategory) {
    throw new Error(
      'Need to include eventAction, eventCategory, eventLabel in the eventOptions'
    );
  }

  const ComponentWithAnalyticsEvent = props => (
    <Wrapped
      {...props}
      onClick={e => {
        console.log('props before', props.href)
        if (typeof props.onClick === 'function') {
          props.onClick();
        }
        let redirect = true;
        if (
          e.button !== 0 ||
          e.altKey ||
          e.ctrlKey ||
          e.metaKey ||
          e.shiftKey ||
          e.defaultPrevented
        ) {
          redirect = false;
        }
        if (props.target && props.target.toLowerCase() !== '_self') {
          redirect = false;
        }

        // redirect mailto
        if (props.href && props.href.includes('mailto:')) {
          redirect = true;
        }

        if (window.gtag) {
          window.gtag('event', eventAction || 'click', {
            event_category: eventCategory || 'outbound',
            event_label: eventLabel || props.href,
            transport_type: 'beacon',
            event_callback: () => {
              if (redirect && props.href) {
                document.location = props.href;
              }
            },
          });
        } else {
          if (redirect) {
            document.location = props.href;
          }
        }

        return false;
      }}
    />
  );

  return ComponentWithAnalyticsEvent;
};

export default withAnalyticsEvent;
