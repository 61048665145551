import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Text } from 'grommet';

const StyledLabel = styled(Text)`
  text-transform: uppercase;
  letter-spacing: 0.1em;
`;

const MyButton = ({ label, ...rest }) => {
  if (!label) {
    return <Button {...rest} />;
  }

  if (typeof label === 'object') {
    return <Button label={label} {...rest} />;
  }

  return (
    <Button
      label={
        <StyledLabel
          size="medium"
          weight="bold"
          color={rest.primary ? 'black' : 'brand'}
        >
          {label}
        </StyledLabel>
      }
      {...rest}
    />
  );
};

MyButton.propTypes = {
  label: PropTypes.string,
};

export default MyButton;
