import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Anchor, ThemeContext } from 'grommet';

const NavAnchor = ({ primary, ...rest }) => {
  const { colors } = useContext(ThemeContext).global;

  const customHover = {
    anchor: {
      hover: {
        textDecoration: null,
        extend: `color: ${colors['brand']};`,
      },
    },
  };

  const primaryHover = {
    anchor: {
      hover: {
        textDecoration: null,
        extend: `color: ${colors['accent-1']};`,
      },
    },
  };

  return (
    <ThemeContext.Extend value={primary ? primaryHover : customHover}>
      <Anchor {...rest} />
    </ThemeContext.Extend>
  );
};

NavAnchor.propTypes = {
  primary: PropTypes.bool,
};

export default NavAnchor;
