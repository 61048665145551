import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { Box } from 'grommet';
// import MenuIcon from '../../images/Menu.svg';
import { Full } from '../linkedLogo';
import { Menu } from 'grommet-icons';
import Button from '../button';
import OverlayMenu from './mobileNavOverlay';

const NAV_BAR_HEIGHT = 75; // in px
const LR_PADDING = '6vw';

const USE_PORTAL = false;

// header styles, fixed or not
const HeaderBackground = styled(Box)(props => {
  if (props.fixed) {
    return {
      position: 'fixed',
      zIndex: 10,
    };
  }
});

// eslint-disable-next-line react/prop-types
const HeaderRow = ({ setOpen, fixed }) => (
  <Box>
    <HeaderBackground
      id="mobile-nav"
      direction="row"
      height={NAV_BAR_HEIGHT + 'px'}
      background="light-1"
      justify="between"
      align="center"
      fill="horizontal"
      elevation="small"
      pad={{ vertical: '5px', horizontal: LR_PADDING }}
      fixed={fixed}
    >
      <Box
        fill="horizontal"
        align="center"
        justify="center"
        pad={{ left: '43px' }}
      >
        <Full height="54" width="150" />
      </Box>
      <Box>
        <Button icon={<Menu />} onClick={() => setOpen(true)} />
      </Box>
    </HeaderBackground>
    {fixed && (
      <div id="spacer" style={{ marginTop: NAV_BAR_HEIGHT + 'px' }}></div>
    )}
  </Box>
);

function MobileHeader({ fixed }) {
  const [open, setOpen] = useState(false);
  const rootEl =
    typeof document !== 'undefined'
      ? document.getElementById('app-root')
      : null;

  if (USE_PORTAL) {
    if (open) {
      return ReactDOM.createPortal(<OverlayMenu setOpen={setOpen} />, rootEl);
    } else {
      return <HeaderRow setOpen={setOpen} fixed={fixed} />;
    }
  } else {
    return (
      <React.Fragment>
        <HeaderRow setOpen={setOpen} fixed={fixed} />
        {open === true && (
          <OverlayMenu
            setOpen={setOpen}
            iconRowHeight={NAV_BAR_HEIGHT}
            iconPad={LR_PADDING}
          />
        )}
      </React.Fragment>
    );
  }
}

MobileHeader.propTypes = {
  fixed: PropTypes.bool,
  setOpen: PropTypes.func,
};

export default MobileHeader;
