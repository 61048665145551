import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { Box, Text } from 'grommet';
import SectionContainer from '../sections/SectionContainer';
import { HWing, Strava, Instagram, Facebook } from '../linkedLogo';

const UpperCaseText = styled(Text)`
  text-transform: uppercase;
`;

class Footer extends PureComponent {
  render() {
    return (
      <footer>
        <SectionContainer background="light-3">
          <Box fill align="center" justify="around" pad="large" gap="large">
            <UpperCaseText weight="bold" size="small" color="black">
              Follow Me
            </UpperCaseText>
            <Box direction="row" align="center" gap="medium">
              <Strava />
              <Instagram />
              <Facebook />
            </Box>
            <HWing width={65} height={53} />
            <Text>&#169;{new Date().getFullYear()} Hawks Running</Text>
          </Box>
        </SectionContainer>
      </footer>
    );
  }
}

export default Footer;
